.btn {
  font-family: $font-family-truth;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  min-width: 224px;
  padding: 14px;
  text-align: center;
  border-width: 2px ;
 /* text-transform: uppercase;*/
}
.btn_big{
  width: 90%;
  height:90px;
  font-size: 30px;

}
.form-control {
  border-radius: 0;
  height: 48px;
  font-size: 16px;
}

.has-error {
  .form-control {
    border-color: $error-color;
  }

  .help-block {
    color: $error-color;
    font-family: $font-family-calibri;
    font-size: 12px;
    line-height: 1;
    text-align: right;
  }
}

.has-success {
  .form-control {
    border-color: $success-color;
  }
}

.btn-primary {
  background: $acent-color;
  border-color: transparent;
  border-radius: 18px;
  color: $text-color-invert;

  &:hover,
  &:focus {
    border-color: $text-color-invert;
    color: $acent-color;
    background: $text-color-invert;


   /* @extend %button-hover-bg;*/
  }

  &:active:focus {
    background: $text-color-invert;
    color: $acent-color;
    border-color: $text-color-invert;
  }

  &:disabled {
    background: $button-disabled-bg;
    color: $button-disabled-text-color;
  }

  &_invert {
    &:hover,
    &:focus {
      border-color: $bg-color-darkblue;
      color: $text-color-invert;
      background: $bg-color-darkblue;


      /* @extend %button-hover-bg;*/
    }

    &:active:focus {
      border-color: $bg-color-darkblue;
      color: $text-color-invert;
      background: $bg-color-darkblue
    }
  }
}

.exit-popup-widget {
  .btn-primary {
    &:hover,
    &:focus {
      border-color: $bg-color-darkblue;
      color: $text-color-invert;
      background: $bg-color-darkblue;


      /* @extend %button-hover-bg;*/
    }

    &:active:focus {
      border-color: $bg-color-darkblue;
      color: $text-color-invert;
      background: $bg-color-darkblue
    }
  }
}

.btn-info {
  background: $text-color-invert;
  border: 2px solid $acent-color;
  border-radius: 18px;
  color: $text-color;
  min-width: 0;
  transition: background 0.2s, color 0.2s;

  &:hover {
    border-color: $acent-color;
    background: $acent-color;
    color: $text-color-invert;
  }
}

.btn-xs {
  padding: 5px 16px;
  font-size: 12px;
  line-height: 1.5;
  min-width: auto;
}

.navbar {
  margin-bottom: 0px;
}


.navbar-toggle {
  margin-right: 0;
  transition: all .4s ease-in-out 0s;

  .icon-bar {
    transition: all .4s ease-in-out 0s;
  }

  .icon-bar + .icon-bar {
    margin-top: 5.5px;
  }
}

.navbar-default {
  background-color: $text-color-invert;
  border-color: $text-color-invert;
}
.navbar {
  margin-bottom: 5px;

  @include respond(phones) {
    margin-bottom: 0;
  }
}

.progress {
  background: $text-color-invert;
  border-radius: 0;
  box-shadow: none;
  height: 48px;
  position: relative;
  border: 1px solid $progress-bar-bg;
  padding: 2px;


  &-note {
    bottom: 0;
    color: $progress-bar-color;
    font-family: $font-family-truth;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    padding-top: 10px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }

  &-striped {
    .progress-bar {
      background: $progress-bar-bg;
      background-image: -webkit-linear-gradient(45deg, $progress-bar-strips-color 25%, transparent 25%, transparent 50%, $progress-bar-strips-color 50%, $progress-bar-strips-color 75%, transparent 75%, transparent);
      background-image: -o-linear-gradient(45deg, $progress-bar-strips-color 25%, transparent 25%, transparent 50%, $progress-bar-strips-color 50%, $progress-bar-strips-color 75%, transparent 75%, transparent);
      background-image: linear-gradient(45deg, $progress-bar-strips-color 25%, transparent 25%, transparent 50%, $progress-bar-strips-color 50%, $progress-bar-strips-color 75%, transparent 75%, transparent);
      background-size: 40px 40px;
      box-shadow: none;
    }
  }
}

label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}

.close {
  font-family: 'Helvetica Neue';
}

.breadcrumb {
  background-color: $text-color-invert;
  padding-left: 0;
}

.breadcrumb a {
  color: $breadcrumb-link-color;
  font-size: 12px;
  text-decoration: underline;

  &:hover {
    border-bottom: 0;
    color: $breadcrumb-link-color;
    text-decoration: none;
  }
}

.breadcrumb > li {
  color: $breadcrumb-link-color;
  font-size: 12px;
  margin-bottom: 0;
}

.breadcrumb > li + li::before {
  background-color: transparent;
  content: url(../img/breadcrumb__arrow.png);
  display: inline-block;
  height: auto;
  margin-right: 3px;
  position: relative;
  top: -2px;
  width: auto;
}

.breadcrumb > li:first-child::before {
  display: none;
}
textarea.form-control{
  height: 150px;
}

.pagination > .active > a,
.pagination > .active > a:hover {
  background-color: $acent-color;
  border-color: $acent-color;
}