.styled-alert {
  background: url('../img/man.png') left top no-repeat;
  background-size: contain;
  position: relative;
  padding-left: 50%;
  padding-bottom: 8.4%;
  padding-top: 4%;


  &__headline {
    color: $acent-color;
    font-family: $font-family-truth;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  &__content {
    border-radius: 10px;
    background: $styled-alert-bg;
    padding: 20px 33px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      border-top: 15px solid transparent;
      border-right: 30px solid $styled-alert-bg;
      border-bottom: 15px solid transparent;
      left: -30px;
      height: 39px;
    }
  }

  &__text {
    color: $text-color;
    font-family: $font-family-truth;
    font-size: 14px;
  }
}