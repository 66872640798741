.about {
  padding-bottom: 81px;

  &__subheader {
    font-size: 26px;
    margin-bottom: 14px;
    margin-top: 56px;

    + p {
      margin-top: 14px;
    }
  }

  &__list {
    margin-left: 24px;
    margin-right: 24px;

    @include respond(phones) {
      margin-left: 0;
      margin-right: 0;
    }

    li {
      font-size: 14px;
    }
  }

  &__headline {
    margin-bottom: 15px;

    + p {
      margin-top: 15px;
    }
  }

  &__text {
    font-size: 14px;
    margin: 1.2em 0;
  }

  &__actions {
    margin-top: 30px;
    text-align: center;
  }
}
