.b-header {
  padding-bottom: 0;
  padding-top: 6px;

  &__get-btn {
    margin-top: 5px;
    position: relative;
    left: -15px;
  }

  &__menu {
    line-height: 48px;
    margin-left: 84px;

    @include respond(small-desktops) {
      margin-left: 20px;
    }

    &_type_blog {
      margin-left: 0;

      @include respond(wide-phones) {
        display: none;
      }
    }
  }

  &__login {
    margin-top: 8px;
  }
}
main{
  overflow: hidden;
}