.b-step {
  position: relative;

  &__content {
    align-items: center;
    background: white;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    z-index: 1;

    &::after {
      border-bottom: 2px solid $b-step-color-innactive;
      content: '';
      height: 3px;
      left: 50%;
      position: absolute;
      right: 0;
      top: 23px;
      transform: translateY(-50%);
      z-index: 0;
    }

    &::before {
      border-bottom: 2px solid $b-step-color-innactive;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      right: 50%;
      top: 23px;
      transform: translateY(-50%);
      z-index: 0;
    }
  }

  &__text {
    color: $b-step-color-innactive;
    font-family: $font-family-truth;
    font-size: 12px;
    text-align: center;

    @include respond(phones) {
      display: none;
    }
  }

  &__num {
    background: $text-color-invert;
    padding: 9px 19px;
    position: relative;
    z-index: 1;

    @include respond(phones) {
      padding: 9px 4px;
    }

    &::after {
      background: transparent;
      border: 2px solid;
      border-radius: 50%;
      color: $b-step-color-innactive;
      content: counter(steps);
      counter-increment: steps;
      display: inline-block;
      font-family: $font-family-avant-garderctt;
      font-size: 18px;
      font-weight: 700;
      height: 30px;
      line-height: 26px;
      text-align: center;
      width: 30px;
    }
  }



  &_done {
    &::after {
      border-bottom-color: $b-step-color;
    }
  }

  &_done & {
    &__text {
      color: $b-step-color;
    }

    &__num {
      &::after {
        color: $b-step-color;
      }
    }

    &__content {
      &::after {
        border-bottom-color: $b-step-color;
      }

      &::before {
        border-bottom-color: $b-step-color;
      }
    }
  }

  &_active & {
    &__text {
      color: $b-step-color;
    }

    &__num {
      &::after {
        color: $b-step-color;
      }
    }

    &__content {
      &::before {
        border-bottom-color: $b-step-color;
      }
    }
  }
}

.b-steps {
  &__item:first-child {
    .b-step {
      &__content {
        &::before {
          display: none;
          left: 50%;
        }
      }

      &__num {

      }
    }
  }

  &__item:last-child {
    .b-step {
      &__content {
        &::after {
          display: none;
        }
      }

      &__num {

      }
    }
  }
}
