.b-steps {
  counter-reset: steps;
  display: flex;

  &__item {
    flex-basis: 100%;
    flex-grow: 1;

    &:last-child {
      /*flex-basis: auto;*/
      flex-grow: 1;
    }
  }
}
