%params_margin {
  margin-bottom: 7px;
}
.float_left{
  float: left;
}
.form-calc {
 /* background: rgba(255, 255, 255, 0.9) url('../img/form-calc__bg.png') right top no-repeat;*/
  padding: 10px 0 0 67px;
  color: #fff;

  @include respond(wide-phones) {
      /* background: $form-calc-bg;*/
      padding: 30px;
      padding-top: 50px;
    }
  @include respond(phones) {
    padding-top: 0px;
  }

  @include respond(phones) {
    display: flex;
    flex-direction: column;
  }

  &__helper {
    &_hidden-xs {
      @include respond(wide-phones) {
        display: none;
      }
    }
  }
  &__row{
    width: 100%;
    font-size: 30px;

    .form-calc__param{
      margin-top: 11px;
      @include respond(phones) {
        margin-top: -7px;
      }

    }
    @include respond(phones) {
      font-size: 24px;
    }

  }

  &::after {
    clear: both;
    content: '';
    display: block;
  }

  &__probability {
    color: $acent-color;
    display: inline-block;
    font-size: 28px;
    margin-right: 11px;
    vertical-align: top;

    &-text {
      display: inline-block;
      text-align: left;
      vertical-align: top;
    }
  }

  &__buttons {
    float: right;
    height: 100%;
    margin-top: 28px;
    text-align: center;
    width: 100%;

    @include respond(wide-phones) {
      margin-top: 30px;
    }

    @include respond(phones) {
      margin-top: 0;
      order: 3;
      padding: 21px 0;
      width: 100%;
    }
  }

  &__result {
    /*font-size: 30px;*/
  }
  &__results {
    text-align: center;

    @include respond(wide-phones) {
      margin-top: 30px;
    }
    /*@include respond(phones) {
      margin-top: 26px;
    }*/
  }

  &__left-col {
    clear: left;
    float: left;
    width: 55%;

    @include respond(wide-phones) {
      border-bottom: 2px solid $form-calc-delimiter-bg;
      float: none;
      padding: 34px 30px;
      width: 100%;

    }
  }

  &__table {
    display: inline-block;
  }

  &__summ-wrapper {
    /*border-bottom: 1px solid $link-border-color;*/
    float: left;
    font-size: 20px;
    font-weight: 400;
    margin-top: -18px;
    margin-left: 17px;

    @include respond(phones) {
      display: inline-block;
      float: right;
      margin-left: 0;
    }
  }

  &__label {
    margin: 15px 0;

    &_position_top {
      font-size: 20px;
      font-weight: 400;
      clear: both;
      @include respond(tablets) {
        margin-top: 50px;
      }
    }

    &::after {
      clear: both;
      content: '';
      display: block;
    }
  }

  &__deadline-wrapper {
    margin-top: 5px;
    padding-bottom: 15px;
  }

  &_inside {
    padding: 10px 67px 0 0px;
    color: $text-color;

    @include respond(tablets) {
      padding: 0;
    }
  }

  &_inside & {
    &__summ {
      color: $text-color;

      @include respond(phones) {
        font-size: 24px;
      }

      &-wrapper {
        display: inline-block;
        float: none;

        @include respond(tablets) {
          margin-top: -5px;
        }

        @include respond(phones) {
          margin-left: 0;
        }
      }
    }

    &__label {
      font-size: 24px;
      margin-bottom: 46px;

      @include respond(phones) {
        font-size: 20px;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }

    &__note {
      margin-top: 44px;
    }
  }
}
.form-calc__header {
  @include respond(phones) {
    max-height: 160px;
  }
}
.form-calc__param {
  clear: left;
  color: $text-color-invert;
  float: left;
  font-size: 20px;
  font-weight: 400;
  width: 50%;

  @extend %params_margin;
  @include respond(wide-phones) {
    font-size: 16px;
  }
}
.form-calc__value{
  color: $text-color-invert;
  float: left;
  font-family: $font-family-avant-garderctt;
  font-size: 20px;
  font-weight: 400;
  width: 50%;

  @extend %params_margin;

  @include respond(wide-phones) {
    font-size: 16px;
  }
}
input.form-calc__summ {
  max-width: 140px;
  background: transparent;
  border: 0;
  display: inline;
  width: auto;
  font-size: 36px;
  text-decoration: underline;
  color: $text-color-invert;
  @include respond(wide-phones) {
    font-size: 30px;
    max-width: 125px;
  }

}
.form-calc_inside {
  input.form-calc__summ {
    border-bottom: 1px solid $link-border-color;
    text-decoration: none;
    line-height: 1;
    padding-bottom: 0;
    width: 130px;

  }
}
.form-calc__footer{
  float: left;
  width: 100%;
  border-top: 1px solid $color-light;
  margin-top: 45px;
  padding-top: 20px;
  @include respond(wide-phones) {
    margin-top: 15px;
  }
}
