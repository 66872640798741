.rangeslider {
  background: $text-color;
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.13);
  margin-top: 20px;

  &--horizontal {
    height: 4px;
  }

  &--horizontal & {
    &__handle {
      top: -12px;
    }
  }

  &__handle {
    background: transparent;
    border: 0;
    box-shadow: none;
    height: 29px;
    width: 29px;

    &::after {
      background: url("/img/range_bullet.png");/*$text-color-invert;*/
      height: 29px;
      width: 29px;
    }

    &:active {
      box-shadow: none;
    }
  }
}

.rangeslider__fill {
  background: $color-light;
}
