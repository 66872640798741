.post-sibling {
  display: inline-block;
  margin-bottom: 10px;


  &__img {
    margin-bottom: 14px;

    @include respond(phones) {
      display: none;
    }
  }

  &__text {
    color: $text-color-blog-siblings;
    font-family: $font-family-museosans;
    font-size: 16px;
    font-weight: 300;
    position: relative;
    display: block;
    padding-left: 72px;
    text-decoration: underline;

    @include respond(phones) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }


  &:hover {
    border-bottom: 0;
  }

  &:hover & {
    &__text {
      text-decoration: none;
    }
  }

  &_right {
    float: right;
  }

  &_left & {
    &__text {
      &::before {
        content: url('../img/post-sibling_left.svg');
        position: absolute;
        top: -50px;
        left: -15px;

        @include respond(phones) {
          top: 0;
        }
      }
    }
  }

  &_right & {
    &__text {
      padding-left: 0;
      padding-right: 72px;
      text-align: right;

      &::after {
        content: url('../img/post-sibling_right.svg');
        position: absolute;
        top: -50px;
        right: -15px;

        @include respond(phones) {
          top: 0;
        }
      }
    }
  }
}