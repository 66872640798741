//Здесь будут все стили которые нужны для отображение первого экрана, чтобы сократить время загрузки для медленных соединений
@import 'bootstrap-header';
@import 'mixins';
@import 'vars';
@import 'bootstrap-custom';
@import 'b-header';
@import 'menu';
@import 'first-screen';
@import 'list';
@import 'acent-text';
@import 'form-calc';
@import 'b-like-table';
@import 'b-range-slider';
@import 'rangeslider-custom';
@import 'b-step';
@import 'b-steps';
@import 'about';
@import 'logo';
@import 'twocolor-bg';
@import 'styled-alert';
@import 'post-sibling';
