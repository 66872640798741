.twocolor-bg{

  background: linear-gradient(to right, #e8e4d9 50%, #005d7c 50%);
  @include respond(tablets) {
      background: linear-gradient(to bottom, $bg-color-darkblue 15%, #005f7c 15%);
    }
  @include respond(phones) {
    background: linear-gradient(to bottom, $bg-color-darkblue 17%, #005f7c 15%);
  }
}
