$acent-color: #e30018;
$bg-color-dark: transparent;
$bg-color-darkblue: #004d64;
$bg-color-blog-offer: #dfe8eb;
$bg-color-blog-ol: #e30018;
$bg-color-blockqoute: #e2e7ec;
$border-color-blog-ol: #484848;
$bg-advantages: rgba(178, 203, 213, 0.15);
$b-step-color-innactive: #84becf;
$b-step-color: #005f7c;
$breadcrumb-link-color: #9e9e9e;
$font-family-arial: Arial, sans-serif;
$font-family-avant-garderctt: 'AvantGardeCTT', Helvetica, sans-serif;
$font-family-calibri: 'Calibri', Helvetica, sans-serif;
$font-family-kundera: 'Kundera', Helvetica, sans-serif;
$font-family-truth: 'TruthCYR', Helvetica, sans-serif;
$font-family-museosans: 'MuseoSans', Helvetica, sans-serif;
$footer-border-color: #0095c9;
$form-calc-bg: #f1f8fc;
$form-calc-delimiter-bg: #c5e0f0;
$step-description-border-color: #93d3ed;
$text-color: #313131;
$text-color-param: #636363;
$text-color-invert: #fff;
$color-light : #00aee3;
$text-color-black: $text-color;
$text-color-dark: $text-color;
$text-color-light: $acent-color;
$text-color-ui: $text-color;
$text-color-blog-meta: #636262;
$text-color-blog-offer: #404142;
$text-color-blog-offer-main: #616060;
$text-color-blog-offer-main-alt: #00a0d5;
$text-color-exit-offer: #173583;
$text-color-blog-siblings: #26a9de;
$link-color: #005f7c;
$link-color-invert: $text-color-invert;
$link-border-color: #a7d5eb;
$progress-bar-color: #36869e;
$progress-bar-bg: #d8eff6;
$progress-bar-strips-color: rgba(0, 0, 0, 0.05);
$select-item-active-bg: #eaeaea;
$select-item-active-text-color: #515151;
$success-color: #29d37d;
$styled-alert-bg: #cfebf2;
$error-color: #d34329;
$border-color: #ccc;
$ol-num-color: $border-color;
$button-bg: #ffd15d;
$button-active-bg: #ebbb34;
$button-disabled-bg: #f7f7f7;
$button-step-bg:  rgba(224, 240, 248, 0.5);
$button-disabled-text-color: #cdcdcd;

%button-hover-bg {
 /* background: -webkit-linear-gradient(90deg, #e30018 0%, #ffd15d 100%);
  background: -moz-linear-gradient(90deg, #e30018 0%, #ffd15d 100%);
  background: -o-linear-gradient(90deg, #e30018 0%, #ffd15d 100%);
  background: -ms-linear-gradient(90deg, #e30018 0%, #ffd15d 100%);
  background: linear-gradient(0deg, #e30018 0%, #ffd15d 100%);*/

  background: transparent;
  border-color: $acent-color ;
  color: $acent-color ;
}
