.b-like-table {
  display: table;
  max-width: 100%;

  &__row {
    display: table-row;
  }

  &__th,
  &__td {
    display: table-cell;
  }

  &__td {
    color: $text-color-invert;
    font-size: 30px;
    line-height: 1;
    padding-right: 8px;
   /* text-transform: uppercase;*/
    word-spacing: 4px;

    @include respond-to(tablets) {
      font-size: 18px;
    }

    @include respond(phones) {
      font-size: 19px;
      padding-right: 2px;
      white-space: nowrap;
      word-spacing: 0;
    }
  }

  &__th {
    color: $acent-color;
    font-size: 12px;
    line-height: 1.4;
    text-align: left;
    text-transform: uppercase;
  }
}

/*
.b-like-table__td .form-calc__param{
  margin-top: 12px;
  width: 70%;
}
*/
