.first-screen {
  /*background-image: url('../img/bg.jpg');*/
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 56px;


  @include respond(tablets) {
    padding:0;
    padding-top: 20px;
  }
  @include respond(wide-phones) {
    background: none;
    padding-top: 10px;
  }

  &__description {
    margin-left: 0px;
    @include respond(wide-phones) {
      margin-left: 30px;
      margin-right: 30px;
      float: left;

    }
  }

  &__headline {
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 0;
    line-height: 1.3;
    color: $text-color;
    font-weight: 400 ;
    text-align: center;

    @include respond(tablets) {
      text-align: center;
      padding: 0;
      font-size: 20px;
      float: left;
      color: $text-color-invert;
      margin-bottom: 0;
    }

  }

  &_theme_2-col & {
    &__headline {
      color: $text-color;
      font-size: 30px;
      font-weight: 400;
     /* text-align: center;*/
    }

    &__subheader {
       color: $text-color;
       font-size: 36px;
       font-weight: 700;
       line-height: 1;
       text-align: center;


     }

    &__description {
      padding-top: 20px;

      &::after {
        content: url('../img/girl.png');
        display: block;
        text-align: center;
      }
    }
  }

  &_theme_2-col {
    background-image: none;
    padding-top: 20px;

    @include respond(wide-phones) {
      padding-bottom: 30px;
    }
  }
}
.first-screen__description:after{
  content: url('../img/girl.png');
  display: block;
  margin-bottom: -05px;
  padding-top: 35px;
  @include respond(tablets){
    display: none;

  }
}
.first-screen__subheader {
  color: $text-color;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
}
.first-screen__subheader{
  .acent-text{
    @include respond(tablets) {
      color: $text-color-invert;
      float: left;
      font-size: 20px;
      margin-right: 5px;
      font-weight: 400;

    }
  }

  @include respond(tablets) {
    padding: 0;
    font-size: 20px;
    float: left;
    color: $text-color-invert;
    margin-left: 10px;
    font-weight: 400;
    margin-left: 3px;

    line-height: 1.4;
  }
}

.mob_head {
  color: #fff;
  font-size: 20px;
  padding: 0px 30px;
  text-align: center;
}