.logo {
  display: block;

  &:hover {
    border-bottom: 0;
  }
}

.logo__upper{
  color: $text-color;
  float: left;
  text-transform: uppercase;
  font-size: 26px;
  margin-left: 8px;
}
.logo__red{
  color: red;
  float: left;
  text-transform: capitalize;
  font-size: 26px;
  margin-left: 8px;
}
.logo__img{
  float: left;
  max-width: 34px;
  margin-top: 2px;
}