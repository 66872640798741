.menu {

  &__headline {
    color: $text-color-dark;
    font-family: $font-family-arial;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 31px;
    margin-top: 0;

    @include respond(wide-phones) {
      margin-bottom: 25px;
      margin-top: 10px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    @include respond(wide-phones) {

    }
  }

  &__item {
    background: none;
    display: block;
    margin-bottom: 5px;
    padding: 0;

    &_has-submenu {
      &::after {
        color: $link-border-color;
        content: '\f107';
        font-family: FontAwesome;
      }

      .menu_type_submenu {
        display: none;
      }

      &:hover {
        .menu_type_submenu {
          display: block;
        }
      }
    }
  }

  &__link {
    font-size: 13px;

  }

  &_type_submenu {
    position: absolute;

    &__item {
      display: block;
      margin-right: 0;
    }
  }

  &_type_footer {
    margin-bottom: 37px;
  }

  &_type_footer & {
    &__headline {
      color: $text-color-invert;
      font-family: $font-family-arial;
      font-size: 17px;
      margin-bottom: 18px;
    }

    &__link {
      border-bottom: 0;
      color: $text-color-invert;
      font-family: $font-family-arial;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &_type_blog {
    line-height: 1;

    @include respond-to(small-desktops) {
      max-width: 527px;
    }

    @include respond-to(tablets) {
      max-width: 306px;
    }

    @include respond(wide-phones) {
      text-align: center;
      padding-left: 26%;
    }
  }

  &_type_main {
    @include respond(wide-phones) {
      display: none;
    }
  }

  &_type_main & {
    text-align: right;



    &__item {
      display: inline-block;
      margin-right: 23px;
      color: $link-color;
      font-size: 18px;

      @include respond(wide-phones) {
        display: block;
      }
    }

    &__link {
      border-bottom: 1px solid $link-border-color;
      font-family: $font-family-arial;
      font-size: 16px;

      @include respond(wide-phones) {
        display: block;
        padding: 15px 0;
      }

      &:hover {
        border-bottom-color: transparent;
        color: $acent-color;
      }
    }
  }

  &_type_blog & {
    text-align: right;

    &__item {
      display: inline-block;
      margin-right: 15px;
      color: $link-color;
      font-size: 18px;
      max-width: 170px;
      vertical-align: middle;

      &:nth-child(3) {
        @include respond-to(tablets) {
          max-width: 121px;
        }
      }

      @include respond-to(small-desktops) {
        max-width: 153px;
      }

      @include respond(wide-phones) {
        display: block;
      }

      @include respond-to(small-desktops) {
        width: 32%;
        margin-bottom: 10px;
      }

      @include respond-to(tablets) {
        width: 49%;
        margin-bottom: 10px;
      }

      @include respond(wide-phones) {
        max-width: none;
      }

      &:first-child {
        max-width: 134px;

        @include respond(small-desktops) {
          max-width: 172px;
        }

        @include respond(tablets) {
          max-width: 122px;
        }

        @include respond(wide-phones) {
          max-width: none;
        }
      }

      &:last-child {
        @include respond(tablets) {
          max-width: 121px;
        }

        @include respond(wide-phones) {
          max-width: none;
        }
      }

      @include respond(wide-phones) {
        display: block;
      }
    }

    &__link {
      border-bottom: 1px solid $link-border-color;
      font-family: $font-family-arial;
      font-size: 16px;

      @include respond(wide-phones) {
        display: block;
        padding: 15px 0;
      }

      &:hover {
        border-bottom-color: transparent;
        color: $acent-color;
      }
    }
  }

  &_type_submenu {
    background: $text-color-invert;
    box-shadow: 0 0 3px rgba($acent-color, 0.3);
    padding: 0 15px;
    position: absolute;
    z-index: 99;
  }

  &_type_submenu & {
    &__item {
      display: block;
      margin-right: 0;
    }
  }
}
