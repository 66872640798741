.b-range-slider {
  margin-bottom: 40px;

  &::after {
    clear: both;
    content: '';
    display: block;
  }

  &__min,
  &__max {
    color: $text-color-invert;
    float: left;
    font-size: 18px;
   /* margin-top: 13px;*/
    width: 50%;

    @include respond(tablets) {
      color: $text-color-invert;
    }
  }

  &__max {
    text-align: right;
  }

  &_inside & {
    &__min,
    &__max {
      color: $text-color;
      margin-top: 0;
    }
  }

  &_inside {
    @include respond(phones) {
      margin-bottom: 0;
    }
  }
}
